<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CASH VOUCHER REPORT</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              :rules="rules.combobox_rule"
              dense
              @change="selected_month"
              outlined
            ></v-select>
            <v-data-table
              dense
              :headers="headers2"
              :items="vouchered_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr @click="activerow(item)"
                    :class="{ 'info white--text': item.id === selectedDepositId }">
                  <td>
                    {{ item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank }}
                  </td>
                  <td>
                    {{ item.date }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length > 0">
            <v-card>
              <h3>
                Print |
                <v-icon class="mr-2" color="success" @click="print_data"
                        v-if="!printt && (!no_cheque_uploaded||auto_deb) && !upload">
                  {{ icons.mdiPrinter }}
                </v-icon>
                <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                     v-if="printt"></v-progress-circular>
              </h3>
              <h2>Request Data | Total: P {{ total_amount }}</h2>
              <v-data-table
                dense
                :headers="headers"
                :items="data_items"
                height="600"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.created_by }}
                    </td>
                    <td>
                      {{ item.sector }}
                    </td>
                    <td>
                      {{ item.particulars }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline, mdiPrinter
    , mdiCloudUpload
    , mdiMonitorEye
    , mdiCheckDecagram
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      key: 0,
      show: false,

      saving_data: false,
      printt: false,
      upload: false,
      can_upload: false,
      auto_deb: false,

      alert: false,
      alert_message: '',
      saving_data_request: false,
      alert_request: false,
      alert_message_request: '',
      total_amount: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Created By', value: 'tin', sortable: false},
        {text: 'Sector', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
        {text: 'Bank', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Payee', value: 'tin', sortable: false},
      ],
      data_items_originaldata_items_original: [],
      data_items: [],
      uploaded_data_items: [],
      data_items2: [],
      request_id: '',
      request_items: [],
      category_id: '',
      category_items: [],
      month_of_id: '',
      month_of_items: [],
      allocation_of_fund: '',
      payee: '',
      is_auto_debit: false,
      cheque_no: '',
      cheque_date: '',
      voucher_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      vouchered_items: [],
      selectedDepositId: -1,
      has_existing_voucher: false,
      existing_voucher: {},

      can_view_uploaded_cheque: false,
      is_deleting: false,

      no_cheque_uploaded: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiCloudUpload,
          mdiMonitorEye,
          mdiCheckDecagram,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'user_id',
        'month_of',
        'name',
        'branch_contact_no',
        'branch_address',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cash_vouchers', ['list_of_cash_voucher_approved_report']),
      ...mapActions('transaction_months', ['retrieve_transaction_month']),

      croppie(e) {
        this.show = true
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        }
        reader.readAsDataURL(files[0])
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
        this.croppieImage = ''
        this.alert = false
      },
      initialize_data() {
        this.retrieve_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_month() {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.selectedDepositId = -1
        this.list_of_cash_voucher_approved_report({
          month_of_id: this.month_of_id,
        })
          .then(response => {
            this.vouchered_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async activerow(value) {
        this.upload = value.is_approved === 2
        this.selectedDepositId = value.id
        var tto = 0
        var datas = []
        value.request_data.forEach(function (item) {
          tto += parseFloat(item.amount)
          datas.push({
            id: item.id,
            request_id: item.request_id,
            created_by: item.request.created_by.name,
            sector: item.request.sector,
            particulars: item.request_particulars.particulars,
            description: item.description,
            amount: item.amount
          })
        });
        this.total_amount = this.formatPrice(tto)
        this.data_items = datas

        this.existing_voucher = value
        this.auto_deb = value.cheque_no === 0

      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []

        var info_array = []
        var cheque_array = {}
        var voc_no = 'CV#' + this.existing_voucher.voucher_no

        widths = [20, 40, 60, 60, 60, 200, 55]
        payments_array.push([
          {text: 'ID', alignment: 'left', style: 'main_info'},
          {text: 'Req. ID', alignment: 'left', style: 'main_info'},
          {text: 'Created By', alignment: 'left', style: 'main_info'},
          {text: 'Sector', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
        ])
        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.request_id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.created_by,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.sector,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.amount === 0
                  ? ''
                  : (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'BANK: ',
                  {
                    text: this.existing_voucher.bank,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'VOUCHER #: ',
                  {
                    text: voc_no,
                    fontSize: 15,
                    bold: true,
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'NAME OF PROJECT: ',
                  {
                    text: this.existing_voucher.allocation_of_fund,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'VOUCHER DATE: ',
                  {
                    text: this.existing_voucher.date,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'PAYEE: ',
                  {
                    text: this.existing_voucher.payee,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'TYPE OF SECTOR: ',
                  {
                    text: this.existing_voucher.type_of_sector,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'SPONSORED BY: ',
                  {
                    text: this.existing_voucher.sponsor_name,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARES FOUNDATION',
                    {text: 'GOODLIFE BLDG. NATIONAL HIGHWAY SAN JOSE DIGOS CITY DAVAO DEL SUR 8002\n', style: 'subheader2'},
                    {
                      text: 'S.E.C REG. No. WAITING CONTACT NO.: NA',
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'CASH VOUCHER',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              text: 'VOUCHER DETAILS',
              style: {
                fontSize: 8,
                alignment: 'left',
                bold: true,
              },
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF TRUSTEES',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT C. ARROCO JR.',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FOUNDER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  columns: [
                    {
                      text: 'VOUCHER #:',
                      fontSize: 9,
                      bold: true,
                      alignment: 'right',
                    },
                    {
                      text: voc_no + ' (BLUE)',
                      fontSize: 10,
                      bold: true,
                      alignment: 'left',
                    },
                  ],
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    },
  }
</script>
